<template>
  <div class="flex min-h-full flex-col bg-white text-slate-500 lg:relative">
    <UiOrganismsToast />
    <div class="flex flex-grow flex-col">
      <main class="flex flex-grow flex-col bg-white">
        <div
          class="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8"
        >
          <!-- a logo do cadastro tem um padding nela, então
          precisamos desse -ml-2.5 -->
          <div class="-ml-2.5 flex-shrink-0 pt-10 sm:pt-16">
            <NuxtLink :to="redirectUrl" class="inline-flex">
              <span class="sr-only">Cadastro Febrafar</span>
              <img
                class="h-12 w-auto"
                src="/cadastro-logo-escrito.png"
                alt="Logo Cadastro"
              />
            </NuxtLink>
          </div>
          <!-- padding embaixo para tentar centralizar o texto -->
          <div class="my-auto flex-shrink-0 pb-24">
            <p class="text-base font-semibold text-cad-magenta-500">
              {{ computedStatus }}
            </p>
            <h1
              class="mt-2 max-w-[500px] text-4xl font-bold tracking-tight text-slate-700 sm:text-5xl"
            >
              {{ computedStatus === 404 ? "Página não encontrada" : "Oops" }}
            </h1>
            <p class="mt-2 max-w-[500px] text-base">
              {{
                error?.statusMessage != null
                  ? error?.statusMessage
                  : "Houve um problema"
              }}
            </p>
            <div class="mt-6">
              <button
                @click="handleError"
                class="text-base font-medium text-cad-magenta-500 hover:text-cad-magenta-400"
              >
                Ir para o início
                <span aria-hidden="true"> &rarr;</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:block lg:w-1/2">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="/background-auth.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  error: Object,
});

const computedStatus = computed(() =>
  props.error?.statusCode != null ? props.error?.statusCode : 500
);

const redirectUrl = computed(() => {
  const { $ability } = useNuxtApp();

  return $ability.cannot("visualizar", "login") ? "/" : "/logout";
});

const handleError = () => clearError({ redirect: redirectUrl.value });
</script>
